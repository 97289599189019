import React from "react";

export const Usage2 = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={200}
            height={156}
            viewBox="0 0 200 156"
            fill="none"
            {...props}
        >
            <rect x={44} y={21} width={82} height={110} rx={8} fill="#666" />
            <rect x={48} y={27} width={74} height={89} rx={6} fill="#BBB" />
            <rect x={57} y={41} width={116} height={76} rx={4} fill="#32393E" />
            <path d="M57 45a4 4 0 014-4h108a4 4 0 014 4v10H57V45z" fill="#FE9235" />
            <circle cx={63.5} cy={47.5} r={2.5} fill="#FFF9F1" />
            <circle cx={70.5} cy={47.5} r={2.5} fill="#FFF9F1" />
            <circle cx={77.5} cy={47.5} r={2.5} fill="#FFF9F1" />
            <circle cx={115} cy={83} r={17} fill="#555" />
            <path d="M123 83l-12 6.928V76.072L123 83z" fill="#fff" />
            <rect x={74} y={24} width={22} height={2} rx={1} fill="#BBB" />
            <circle cx={85} cy={123} r={4} fill="#BBB" />
            <path
                d="M74.993 89.395l-15.7-29.684s4.5-1.05 8.206 6.277C71.225 73.315 79.934 89.5 79.934 89.5l-4.94-.105z"
                fill="#EEE"
            />
            <path fill="#157BF2" d="M17 44H67V136H17z" />
            <path fill="#299BEF" d="M24 44H60V136H24z" />
            <rect x={19} y={46} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={51} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={56} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={61} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={66} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={71} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={76} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={81} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={86} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={91} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={96} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={101} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={106} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={111} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={116} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={121} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={126} width={3} height={3} rx={1} fill="#fff" />
            <rect x={19} y={131} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={46} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={51} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={56} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={61} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={66} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={71} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={76} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={81} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={86} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={91} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={96} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={101} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={106} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={111} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={116} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={121} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={126} width={3} height={3} rx={1} fill="#fff" />
            <rect x={62} y={131} width={3} height={3} rx={1} fill="#fff" />
            <path fill="#EEF8FF" d="M24 74H60V75H24z" />
            <path fill="#EEF8FF" d="M24 105H60V106H24z" />
            <path stroke="#EEF8FF" d="M27.5 47.5H56.5V70.5H27.5z" />
            <path stroke="#EEF8FF" d="M27.5 78.5H56.5V101.5H27.5z" />
            <path stroke="#EEF8FF" d="M27.5 109.5H56.5V132.5H27.5z" />
            <path
                d="M43.54 65h-1.845v-7.352c0-.244.003-.49.008-.734l.016-.719c.01-.234.023-.458.039-.672-.11.12-.245.25-.406.391-.157.14-.323.286-.5.438l-1.313 1.046-.922-1.164 3.39-2.656h1.532V65zM45.875 96h-7.734v-1.383l2.96-3a37.486 37.486 0 001.415-1.508c.37-.427.643-.83.82-1.21.182-.38.273-.795.273-1.243 0-.552-.159-.969-.476-1.25-.318-.286-.737-.43-1.258-.43-.495 0-.953.1-1.375.297a6.383 6.383 0 00-1.297.829l-1.008-1.211c.318-.271.657-.519 1.016-.743.364-.224.77-.4 1.219-.53a5.293 5.293 0 011.53-.204c.725 0 1.35.13 1.876.39.526.256.93.613 1.21 1.071.287.458.43.992.43 1.602 0 .614-.122 1.182-.367 1.703a6.544 6.544 0 01-1.047 1.547 29.21 29.21 0 01-1.617 1.648l-1.976 1.93v.086h5.406V96zM45.438 118.188c0 .515-.105.963-.313 1.343-.208.38-.495.693-.86.938-.359.239-.775.411-1.25.515v.055c.912.115 1.6.401 2.063.859.469.459.703 1.066.703 1.821 0 .661-.159 1.252-.476 1.773-.318.516-.805.922-1.461 1.219-.657.297-1.5.445-2.532.445-.62 0-1.195-.049-1.726-.148a6.371 6.371 0 01-1.5-.477v-1.633c.484.24 1 .425 1.547.555a6.73 6.73 0 001.554.195c.954 0 1.633-.177 2.04-.531.406-.359.609-.854.609-1.484 0-.406-.107-.74-.32-1-.209-.261-.534-.456-.977-.586-.437-.13-1.003-.195-1.695-.195h-1.008v-1.477h1.016c.661 0 1.192-.078 1.593-.234.401-.162.69-.383.867-.664.183-.282.274-.61.274-.985 0-.489-.159-.87-.477-1.14-.317-.276-.789-.414-1.414-.414-.385 0-.737.044-1.054.132a4.83 4.83 0 00-.868.336 8.425 8.425 0 00-.757.438l-.883-1.274a6.192 6.192 0 011.547-.82c.599-.224 1.297-.336 2.093-.336 1.172 0 2.076.253 2.711.758.636.5.953 1.172.953 2.016z"
                fill="#fff"
            />
            <path
                d="M99.655 124.118c.669 3.338 2.428 7.683 2.533 9.993.021.357-.042.503-1.215.776-1.172.273-.104 1.029 1.906 1.113 2.009.084 1.402-9.636.648-14.212-.669-4.01-4.647-1.533-3.872 2.33z"
                fill="#FFB69E"
            />
            <path
                d="M110.876 103.188s-10.342 7.263-11.829 11c-1.34 3.359 1.759 16.837 1.759 16.837s2.177.86 4.187-.357c0 0-1.298-8.083-.272-10.476 1.151-2.729 8.73-7.998 8.73-7.998l-2.575-9.006z"
                fill="#55C7A6"
            />
            <path
                d="M116.067 126.973c2.889 2.225 7.348 4.493 9.044 6.256.272.273.335.42-.439 1.49-.754 1.05.628.882 2.302-.461 1.675-1.344-5.631-8.755-9.441-11.903-3.329-2.751-4.794 2.036-1.466 4.618z"
                fill="#FFB69E"
            />
            <path
                d="M112.404 102.915s-5.359 12.26-3.957 16.291c1.277 3.631 13.231 12.301 13.231 12.301s2.365-.839 3.119-3.232c0 0-5.862-5.795-6.469-8.545-.711-3.212 1.926-9.048.44-12.595-.042-.105-6.364-4.22-6.364-4.22z"
                fill="#55C7A6"
            />
            <path
                d="M107.275 93.426c-.461-.21-2.533.609-3.308 1.805-1.068 1.596-.879 4.556-3.245 5.732-2.386 1.175-3.873.168-4.94-.378-1.068-.546-1.256-1.26-1.926-1.512-.67-.252-.921.567.607 1.848.733.608 3.956 4.093 7.055 3.233 4.04-1.134 8.478-9.468 5.757-10.728z"
                fill="#FFB69E"
            />
            <path
                d="M118.6 106.736c.021 0 .021 0 0 0 .021-.021.021 0 0 0-.041-.084-.104-.147-.146-.231-1.926-3.212-3.287-5.206-3.182-9.006.167-5.458-3.831-4.976-6.281-4.745-2.449.231-6.301 1.365-6.196 5.731.063 3.149 1.193 7.222 3.161 9.825 2.261 2.981 15.366 1.764 12.644-1.574z"
                fill="#55C7A6"
            />
            <path
                d="M98.022 105.854c.041-3.128-.503-5.647-9.086-8.733-3.224-1.155-7.704-3.779-9.525-9.825l-4.92 1.596s4.773 20.426 12.959 23.827c6.092 2.561 10.53-1.47 10.572-6.865zm-7.85 5.332c-3.204-.462-5.193-6.613-6.386-9.216-1.193-2.603.817-2.645.817-2.645 1.863.042 5.903 2.099 8.939 4.43 3.014 2.351-.168 7.893-3.37 7.431z"
                fill="#FE9235"
            />
            <path
                d="M79.808 83.245l-31.61-12.093s-.545 4.535 7.242 7.369c7.788 2.834 25.038 9.594 25.038 9.594l-.67-4.87z"
                fill="#fff"
            />
            <path
                d="M104.218 97.604c3.078-4.01 5.736-16.396-12.225-16.228-3.183.042-8.646 2.456-12.687.483v6.235c0-.021 18.485 17.886 24.912 9.51zm-16.413-6.298s-4.835-5.374 1.654-6.445c0 0 5.443-1.197 10.154 1.301 4.71 2.478 4.898 7.873 1.926 10.413-2.764 2.351-6.47.609-13.734-5.27z"
                fill="#FE9235"
            />
            <path
                d="M114.665 94.245c-.398-.441-1.78-.756-3.287.105-2.01 1.133-4.689 4.45-7.913 4.492-3.245.063-4.334-1.7-5.234-2.77-.9-1.092-.712-1.932-1.298-2.499-.607-.567-1.298.126-.335 2.204.482.987 2.177 6.151 6.029 6.739 5.046.798 14.361-5.668 12.038-8.271z"
                fill="#FFB69E"
            />
            <path
                d="M108.615 89.563s-.545 2.079.984 2.834c1.57.777-3.224 4.619-3.308 3.485-.105-1.47.481-2.456-.523-3.988l2.847-2.33z"
                fill="#FFB69E"
            />
            <path
                d="M104.93 86.498c-1.005 1.386-1.382 6.697.105 7.579.649.378 2.847-.588 3.873-3.107.418-1.008.858-2.876-.398-4.43-.544-.693-2.408-1.637-3.58-.042z"
                fill="#FFB69E"
            />
            <path
                d="M103.8 87.338c-.147.693-.042.819 1.905.756 1.968-.063 2.261-.252 2.156.252-.314 1.448.984.923.586 2.645-.398 1.721 1.026.462 1.487-.966.104-.294.648-1.721-.629-3.548-1.528-2.12-4.94-1.637-5.505.861z"
                fill="#32393E"
            />
        </svg>
    )
}
