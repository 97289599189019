import React from "react";

export const Usage3 = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={200}
            height={156}
            viewBox="0 0 200 156"
            fill="none"
            {...props}
        >
            <path
                d="M136 145H37L18.987 83.24C17.493 78.12 21.333 73 26.667 73h79.964a8 8 0 017.603 5.511L136 145z"
                fill="#555"
            />
            <path
                d="M129 137H40.451L25.278 85.702C24.141 81.857 27.022 78 31.032 78h74.835a6 6 0 015.718 4.183L129 137z"
                fill="#666"
            />
            <path
                d="M159 137H37.562a2 2 0 00-1.94 2.485l1 4a2 2 0 001.94 1.515H159a2 2 0 002-2v-4a2 2 0 00-2-2z"
                fill="#555"
            />
            <path fill="#157BF2" d="M67 25H124.1429V137H67z"/>
            <path fill="#299BEF" d="M75 25H116.1429V137H75z"/>
            <rect
                x={69.2857}
                y={27.2858}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={33.0001}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={38.7144}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={44.4287}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={50.1429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={55.8573}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={61.5715}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={67.2859}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={73.0001}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={78.7144}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={84.4287}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={90.1429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={95.8573}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={101.572}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={107.286}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={113}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={118.714}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={124.429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={69.2856}
                y={130.143}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={27.2858}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={33.0001}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={38.7144}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={44.4287}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={50.1429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={55.8573}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={61.5715}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={67.2859}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={73.0001}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={78.7144}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={84.4287}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={90.1429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={95.8573}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={101.572}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={107.286}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={113}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={118.714}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={124.429}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <rect
                x={118.429}
                y={130.143}
                width={3.42857}
                height={3.42857}
                rx={1}
                fill="#fff"
            />
            <path fill="#EEF8FF" d="M75 59.2858H116.1429V60.42866H75z"/>
            <path fill="#EEF8FF" d="M75 99.2858H116.1429V100.42866H75z"/>
            <circle cx={65} cy={29} r={20} fill="#157BF2"/>
            <mask
                id="a"
                style={{
                    maskType: "alpha"
                }}
                maskUnits="userSpaceOnUse"
                x={45}
                y={9}
                width={41}
                height={40}
            >
                <circle cx={65.0001} cy={29} r={20} fill="#157BF2"/>
            </mask>
            <g mask="url(#a)">
                <path
                    d="M56.583 39.168s-7.616 2.026-7.645 11.057c-.028 8.983 5.394 29.6 5.28 32.968-.227 6.102-9.96 6.462-6.286 6.766 4.762.406 7.757 2.563 8.911-2.12.787-3.219 4.663-34.01 4.266-39.235-.398-5.273.857-9.223-4.526-9.436z"
                    fill="#FFB69E"
                />
                <path
                    d="M92.25 67.3c-.14-.2-.292-.334-.431-.536-5.485-6.991-9.345-11.293-10.209-20.29-1.3-12.866-10.544-10.642-16.194-9.355-5.677 1.246-14.347 4.98-12.767 15.27 1.15 7.367 5.013 16.613 10.427 22.224 6.158 6.346 36.535-.248 29.173-7.312z"
                    fill="#252D5C"
                />
                <path
                    d="M76.203 37.216s7.736-.217 10.342 8.773c2.606 8.989 5.184 31.888 4.335 35.34-.822 3.406 10.227 5.179 6.82 6.541-3.425 1.293-5.419 2.522-7.832-1.812C88.193 83.05 75.76 53.335 74.622 48.01c-1.07-5.345-3.378-8.961 1.581-10.794zM69.456 28.41s-1.972 4.67 1.827 7.598c3.89 3.001-9.68 9.004-9.595 6.223.177-3.634 1.885-5.668-.359-10.07l8.127-3.75z"
                    fill="#FFB69E"
                />
                <path
                    d="M57.157 19.54c-1.714 3.636.146 16.077 4.142 17.47 1.723.564 6.47-2.674 7.69-8.886.507-2.58.52-7.027-3.2-10.058-1.728-1.425-6.627-2.784-8.632 1.474z"
                    fill="#FFB69E"
                />
                <path
                    d="M68.512 16.123c3.938 3.048 3.564 7.164 3.535 7.877-.233 2.719-2.185 5.833-2.935 5.233-.926-.696 1.097-2.13-.76-4.295-1.859-2.164-.094-3.05-1.446-5.035-1.353-1.986-3.21-.564-8.291.937-2.056.615-2.877-1.178-2.758-2.801 1.661-4.419 8.518-5.168 12.655-1.916z"
                    fill="#32393E"
                />
            </g>
            <circle cx={95} cy={29} r={20} fill="#FE9235"/>
            <mask
                id="b"
                style={{
                    maskType: "alpha"
                }}
                maskUnits="userSpaceOnUse"
                x={75}
                y={9}
                width={40}
                height={40}
            >
                <circle cx={95} cy={29} r={20} fill="#299BEF"/>
            </mask>
            <g mask="url(#b)">
                <path
                    d="M96.105 11.99c2.156 0 7.791.894 9.074 5.603.585 5.33 1.601 3.74 3.625 5.501 5.174 4.561-4.319 8.897 0 13.232C125.405 52.81 86.329 58.32 87.138 49.332c1.08-11.696-7.538-4.072-6.998-11.252 1.233-4.078-2.812-7.082 2.181-11.192 1.665-1.355-1.356-7.604 4.05-10.449 4.52-1.738 4.38-4.45 9.734-4.45z"
                    fill="#32393E"
                />
                <path
                    d="M89.947 37.946s5.536-1.553 11.903-1.02c0 0 4.51.497 5.123 1.02 5.299 4.717 5.658 12.204 6.368 28.117s-.511 22.06-.511 22.06-6.946 3.652-17.776 3.122c-7.181-.323-14.127-2.825-18.88-5.983 0 0 5.32-15.826 4.692-21.997-.374-3.713-.37-5.61-.362-14.215.011-8.101 7.172-10.502 7.172-10.502l2.27-.602z"
                    fill="#FE7426"
                />
                <path
                    d="M132.601 59.594c-1.662.724-2.871 1.133-5.037 1.396-16.605 1.967-32.5-13.277-30.862-19.341.82-2.896 4.043-7.814 12.564-2.35 6.925 4.396 16.376 13.195 24.775 6.218 0 0 1.204 7.373-1.44 14.077z"
                    fill="#FE7426"
                />
                <path
                    d="M91.118 29.837s2.72 4.497-.08 7.64c-2.929 3.225 10.614 8.32 9.937 5.491-.9-3.603-5.053-3.914-1.76-9.992l-8.097-3.14z"
                    fill="#FFB69E"
                />
                <path
                    d="M101.579 18.668c2.276 3.697.387 15.642-3.66 17.537-2.653 1.242-7.182-.923-9.328-7.663-.874-2.778-1.334-6.51 2.165-10.687 1.612-1.93 8.21-3.53 10.823.813z"
                    fill="#FFB69E"
                />
                <path
                    d="M87.362 28.497c4.847-2.617 7.11-4.489 10.227-8.646 1.874 2.546 3.31 3.528 6.514 4.534l-1.672-7.14-3.264-2.138h-3.393l-4.29.603-3.617 3.509-.505 9.278z"
                    fill="#32393E"
                />
            </g>
            <circle cx={125} cy={29} r={20} fill="#299BEF"/>
            <mask
                id="c"
                style={{
                    maskType: "alpha"
                }}
                maskUnits="userSpaceOnUse"
                x={104}
                y={9}
                width={41}
                height={40}
            >
                <circle cx={125} cy={29} r={20} fill="#299BEF"/>
            </mask>
            <g mask="url(#c)">
                <path
                    d="M119.032 35.892s6.297-1.998 12.664-1.467c0 0 2.433.636 3.047 1.16 5.298 4.716 6.973 12.51 7.683 28.424.71 15.913-.511 22.06-.511 22.06s-6.946 3.652-17.776 3.122c-7.181-.324-14.127-2.825-18.88-5.984 0 0 5.319-15.825 4.692-21.996-.374-3.713-.637-7.682-.629-16.287.011-8.102 7.031-8.268 7.031-8.268l2.679-.764z"
                    fill="#157BF2"
                />
                <path
                    d="M164.906 60.069c-1.662.724-2.871 1.133-5.036 1.396-16.606 1.967-32.501-13.277-30.862-19.342.819-2.895 4.042-7.813 12.563-2.349 6.925 4.396 16.376 13.195 24.775 6.218 0 0 1.204 7.372-1.44 14.077z"
                    fill="#157BF2"
                />
                <path
                    d="M116.601 43.716s2.458-3.825 6.991-1.147c5.354 3.114 11.198 16.773 19.501 18.74 8.303 1.913 12.236-2.24 15.24-4.753 3.059-2.459 3.113-4.808 5.025-6.065 1.912-1.257 3.278 1.093-.601 6.174-1.857 2.404-9.613 15.572-19.937 15.08-13.547-.655-31.409-22.292-26.219-28.029z"
                    fill="#FFB69E"
                />
                <path
                    d="M157.35 55.245l3.878 4.972s-7.046 16.391-23.269 12.403c-16.223-3.989-25.728-23.877-22.014-28.958 1.748-2.46 6.555-5.847 12.564 2.24 6.008 8.032 13.328 23.658 28.841 9.343z"
                    fill="#157BF2"
                />
                <path
                    d="M121.203 27.36s2.72 4.498-.079 7.64c-2.93 3.226 10.613 8.32 9.937 5.492-.901-3.603-2.898-5.486-1.761-9.992l-8.097-3.14z"
                    fill="#FFB69E"
                />
                <path
                    d="M127.195 16.914c3.275 2.437 7.979 14.85 5.359 18.2-1.158 1.483-6.754 1.005-10.965-4.093-1.726-2.104-4.034-6.178-2.401-10.912.75-2.186 4.214-6.08 8.007-3.195z"
                    fill="#FFB69E"
                />
                <path
                    d="M127.166 24.21c.151.474-.12 1.232-.663 1.406-.542.174-1.153-.318-1.305-.791-.152-.473.212-.748.755-.922.543-.174 1.061-.165 1.213.308zM130.924 22.788c.133.412-.052 1.056-.465 1.188-.412.133-.899-.31-1.031-.723-.133-.412.14-.636.552-.768.413-.133.812-.109.944.303z"
                    fill="#32393E"
                />
                <path
                    d="M130.319 17.977c.857 1.47.712 1.886-3.692 3.397-4.405 1.511-5.206 1.339-4.6 2.42 1.775 3.176-1.502 3.085.638 6.9 2.141 3.813-1.924 1.933-4.012-1.088-.431-.595-2.755-3.555-1.186-9.03 1.765-6.382 9.752-8.184 12.852-2.599z"
                    fill="#32393E"
                />
                <path
                    d="M122.612 30.682s.83 1.073 3.082.216c2.305-.845 5.597-3.937 6.836-3.726 1.24.21 2.283 7.31-.119 8.86-2.389 1.498-9.033-.602-11.045-3.718-.407-.703 1.246-1.632 1.246-1.632z"
                    fill="#32393E"
                />
                <path
                    d="M117.402 20.619c-1.702 5.054.767 8.38 1.186 9.03 1.574 2.294 3.155 4.032 5.943 5.358.309.122-1.693-1.482-1.368-2.474.414-1.14-2.513-1.044-2.083-4.081.431-3.037-1.408-3.321-1.306-5.87.101-2.55 1.856-2.395 6.863-4.112 2.11-.72 2.359-1.616 1.986-2.479-3.663-2.968-9.396-.736-11.221 4.628z"
                    fill="#32393E"
                />
                <path
                    d="M129.008 32.114s.75-2.185 2.235-2.592c1.526-.343 1.486 1.66 1.272 2.397-.268.725-2.425 2.44-3.661 1.95-1.182-.477-.178-.988.154-1.755z"
                    fill="#FFB69E"
                />
            </g>
            <rect x={108} y={61} width={60} height={60} rx={8} fill="#FE9235"/>
            <path
                d="M154.214 110h-30.429c-.053-.025-.105-.06-.162-.072-2.185-.461-3.623-2.231-3.623-4.467 0-9.678-.001-19.355.002-29.034.001-1.468.633-2.635 1.777-3.538.593-.468 1.294-.69 2.006-.889h27.461c.052.066.099.138.158.198a7716.17 7716.17 0 006.398 6.396c.059.059.132.107.198.159v27.461c-.026.065-.06.129-.076.197-.396 1.69-1.405 2.826-3.051 3.389-.217.075-.439.134-.659.2zm-4.824-34.995v.414c0 2.238.003 4.478-.001 6.716-.004 2.42-1.993 4.409-4.416 4.41-3.959.004-7.917.004-11.875 0-2.42-.001-4.41-1.993-4.413-4.413-.003-2.251-.001-4.502-.001-6.754v-.41h-3.908c-1.248 0-1.808.562-1.808 1.81v28.425c0 .124-.007.249.003.372.053.632.337 1.161.954 1.324.567.148 1.179.123 1.789.177v-.549-12.506c.001-2.547 1.959-4.504 4.508-4.504h17.626c2.551 0 4.507 1.955 4.508 4.504v13.011h.956c1.121-.002 1.716-.596 1.716-1.716.001-8.312-.001-16.625.006-24.937a.748.748 0 00-.246-.578c-1.511-1.5-3.019-3-4.512-4.516-.247-.253-.492-.388-.886-.28zm-20.707 32h20.708v-.394-12.39c0-1.148-.588-1.738-1.732-1.738h-17.251c-1.141 0-1.725.59-1.726 1.743v12.391l.001.388zm11.81-32.01h-8.841v6.888c.001 1.094.604 1.695 1.699 1.695h11.354c1.119 0 1.715-.597 1.716-1.717v-6.493-.378h-2.98v5.6h-2.948v-5.594z"
                fill="#EEF8FF"
            />
        </svg>
    )
}
